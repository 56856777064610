import React from "react"

const PageNotFound = () => {
  return (
    <div className="page__wrapper">
      <h1 className="page__title">404 Pagina niet gevonden</h1>
    </div>
  );
}

export default PageNotFound
